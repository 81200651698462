<template>
  <div id="regions-view">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <vs-table hoverFlat ref="table" :data="data" stripe pagination :max-items="itemsPerPage">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-no-wrap">
            <div class="flex flex-wrap-reverse items-center">
            </div>
          </div>
        </div>

        <template slot="thead">
          <vs-th class="pr-0"></vs-th>
          <vs-th class="pl-0">ID</vs-th>
          <vs-th class="col">{{ $t('fields.title') }}</vs-th>
          <!-- <vs-th>Express</vs-th> -->
          <vs-th class="w-auto" >{{ $t('fields.actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
            <vs-td class="pr-0">
              <feather-icon v-if="tr.type === 'cities'" icon="StarIcon" svgClasses="w-5 h-5 text-warning" />
              <feather-icon v-else icon="PhoneIcon" svgClasses="w-5 h-5" />
            </vs-td>
            <vs-td class="pl-0" :data="data[indextr].id">
              {{ data[indextr].id }}
            </vs-td>
            <vs-td class="pl-0" :data="data[indextr].name">
              {{ data[indextr].name }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].isExpress">
              <vs-chip v-if="tr.isExpress !== undefined" :color="tr.isExpress ? 'primary' : 'danger'">
                <feather-icon v-if="tr.isExpress" icon="CheckIcon" svgClasses="w-5 h-5" />
                <span v-if="tr.isExpress">{{tr.express_time}}min</span>
                <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
              </vs-chip>
            </vs-td> -->
            <vs-td class="whitespace-no-wrap">
              <!-- <feather-icon v-if="tr.type === 'regions'" icon="TruckIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" title="View couriers" @click.stop="showData(tr.uuid, 'region')" /> -->
              <!-- <feather-icon v-else icon="MenuIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" title="View couriers" @click.stop="showData(tr.uuid, 'city')" /> -->
              <!-- <feather-icon v-if="$acl.check('admin')" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" title="Edit banner details" @click.stop="editData(tr.uuid, tr.type)" /> -->
              <!-- <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" title="Change banner status" @click.stop="changeStatus(tr.uuid, tr.type, tr.isActive)" /> -->
              <feather-icon v-if="$acl.check('admin')" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" />
            </vs-td>
          </vs-tr>
        </template>

      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mobiles: [],
      itemsPerPage: 15,
      showModal: false,
      isLoading: true
    }
  },
  computed: {
    data () {
      return this.mobiles
    },
    currentPage () { return this.isLoading ? 0 : this.$refs.table.currentx },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.data.length }
  },
  async created () {
    await this.fetchData()
  },
  methods: {
    async fetchData () {
      await this.$http.get('/mobiles').then(response => {
        this.mobiles = response.data
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.isLoading = false
    },
    deleteData (id) {
      this.$http.delete(`/mobiles/delete/${id}`).then(response => {
        if (response.status === 200) {
          this.$vs.notify({
            title: 'Success',
            text: 'Region successfully deleted!',
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'primary'
          })
          this.fetchData()
        }
      }).catch(err => {
        this.$vs.notify({
          title: 'Error',
          text: err.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#regions-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>